<script setup>
import { useDisplay } from "vuetify";
import DownArrowSVG from "@images/svg/down-arrow.svg";
import backgroundImage from "@images/front-pages/landing/z-blockchains.webp";
const { mdAndUp } = useDisplay();
</script>

<template>
  <div class="section1-container d-flex align-center">
    <div class="section1-wrapper">
      <VImg :src="backgroundImage" class="background-image" />
      <div class="content">
        <div class="mx-8 mx-md-16 px-md-10 h-100 d-flex align-end">
          <VRow dense>
            <VCol cols="12" md="4" class="mb-6 mb-md-14">
              <div class="mb-6 mb-md-10 d-flex">
                <span class="text-subtitle-1 text-ltrenovate text-uppercase bg-dark-overlay-md">
                  [ {{ $t("zchains.env_compatible_l1") }} ]
                </span>
              </div>
              <h1 class="text-h1">
                <span>{{ $t("zchains.build_on_zchains[0]") }}</span>
                <span class="text-primary">
                  <i>{{ $t("zchains.build_on_zchains[1]") }}</i>
                </span>
              </h1> 
            </VCol>
            <VCol offset-md="4" class="mb-12 d-flex align-end">
              <div class="bg-dark-overlay">
                <div class="mb-6 mr-3 pr-sm-16 pr-md-0 mr-sm-16 mr-md-6">
                  <span class="text-h6 text-geomanist">
                    {{ $t("zchains.content") }}
                  </span>
                </div>
                <div class="d-flex d-md-inline-flex flex-column gap-3">
                  <div
                    class="d-flex d-md-inline-flex flex-grow-1 flex-md-grow-0 justify-space-between align-center gap-4"
                  >
                    <div>
                      <span class="text-h6 text-ltrenovate text-uppercase">
                        {{ $t("zchains.scroll_to_start") }}
                      </span>
                      <div v-show="!mdAndUp" class="double-line mt-2" />
                    </div>
                    <DownArrowSVG />
                  </div>
                  <div v-show="mdAndUp" class="double-line" />
                </div>
              </div>
            </VCol>
          </VRow>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@configured-variables" as variables;

.section1-container { 
  transform: translate(0%, -(variables.$layout-horizontal-nav-navbar-height));
  margin-bottom: -(variables.$layout-horizontal-nav-navbar-height);
  height: 100vh;
  overflow: hidden;
  @media (max-width: 960px) and (max-height: 500px) and (orientation:landscape) {
    height: fit-content;
    transform: translate(0%,0%);
    margin-bottom: 0;
  }
}

.section1-wrapper {
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(
    100vh - variables.$layout-horizontal-nav-navbar-height
  );
  overflow: hidden;

  @media (max-width: 960px) and (max-height: 500px) and (orientation:landscape) {
    height: fit-content;
  }

  .content {
    width: 100%;
    height: 100%;
    position: relative;
  }
}

.background-image {
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  transform: translate(0%, -90px);
  top: 50%;
  left: 80%;
  transform: translate(-80%, calc(-50% - variables.$layout-horizontal-nav-navbar-height));
}

.double-line {
  height: 3px;
  border-top: 1px solid rgb(var(--v-global-theme-primary));
  border-bottom: 1px solid rgb(var(--v-global-theme-primary));
}

.bg-dark-overlay {
  padding: 10px;
  background-color: rgba(black, 0.5);
}

@media (max-width: 960px) {
  .bg-dark-overlay-md {
    padding: 10px;
    background-color: rgba(black, 0.5);
  }
}
</style>
