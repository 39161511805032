<script setup>
import downArrow from '@images/front-pages/landing/down-arrow.png'
import webIcon from '@images/front-pages/landing/web-icon.png'
import telegramLogo from '@images/front-pages/landing/telegram-logo.png'
import twitterLogo from '@images/front-pages/landing/twitter-logo.png'
import discordLogo from '@images/front-pages/landing/discord-logo.png'
import instagramLogo from '@images/front-pages/landing/instagram-logo.png'
import youtubeLogo from '@images/front-pages/landing/youtube-logo.png'

const config = useRuntimeConfig()
const telegramLink = config.public.socialLinksTelegram
const twitterLink = config.public.socialLinksTwitter
const discordLink = config.public.socialLinksDiscord
const instagramLink = config.public.socialLinksInstagram
const youtubeLink = config.public.socialLinksYoutube

const navigateTo = url => {
  window.open(url, '_blank', 'noopener,noreferrer')
}

const communities = [
  {
    logo: telegramLogo,
    link: telegramLink,
    content: 'community.telegram_content',
    name: 'community.telegram',
  },
  {
    logo: twitterLogo,
    link: twitterLink,
    content: 'community.twitter_content',
    name: 'community.twitter',
  },
  {
    logo: discordLogo,
    link: discordLink,
    content: 'community.discord_content',
    name: 'community.discord',
  },
  {
    logo: instagramLogo,
    link: instagramLink,
    content: 'community.instagram_content',
    name: 'community.instagram',
  },
  {
    logo: youtubeLogo,
    link: youtubeLink,
    content: 'community.youtube_content',
    name: 'community.youtube',
  },
]

const { $gsap, $Draggable } = useNuxtApp()

onMounted(() => {
  if (window.innerWidth >= 960) {
    const loops = $gsap.utils.toArray('.card-container').map((container, i) => {
      const cards = container.querySelectorAll('.card')
      const tickerDirection = !isEven(i)

      const tl = horizontalLoop(cards, {
        repeat: -1,
        speed: 1 + i * 0.25,
        draggable: true,
        reversed: tickerDirection,
        paddingRight: Number.parseFloat($gsap.getProperty(cards[0], 'marginRight', 'px')),
      })

      cards.forEach(card => {
        card.addEventListener('mouseenter', () => $gsap.to(tl, { timeScale: 0, overwrite: true }))
        card.addEventListener('mouseleave', () => $gsap.to(tl, { timeScale: 1, overwrite: true }))
      })
    })

    function isEven(n) {
      return n % 2 === 0
    }

    function horizontalLoop(items, config) {
      items = $gsap.utils.toArray(items)
      config = config || {}

      const tl = $gsap.timeline({
        repeat: config.repeat,
        paused: config.paused,
        defaults: { ease: 'none' },
        onReverseComplete: () => tl.totalTime(tl.rawTime() + tl.duration() * 100),
      })

      const length = items.length
      const startX = items[0].offsetLeft
      const times = []
      const widths = []
      const xPercents = []
      let curIndex = 0
      const pixelsPerSecond = (config.speed || 1) * 100
      const snap = config.snap === false ? v => v : $gsap.utils.snap(config.snap || 1)
      const margin = Number.parseFloat(window.getComputedStyle(items[0]).marginRight) 

      const populateWidths = () => items.forEach((el, i) => {
        widths[i] = Number.parseFloat($gsap.getProperty(el, 'width', 'px'))
        xPercents[i] = snap(Number.parseFloat($gsap.getProperty(el, 'x', 'px')) / widths[i] * 100 + $gsap.getProperty(el, 'xPercent'))
      })

      const getTotalWidth = () => items[length - 1].offsetLeft + xPercents[length - 1] / 100 * widths[length - 1] - startX + items[length - 1].offsetWidth * $gsap.getProperty(items[length - 1], 'scaleX') + (Number.parseFloat(config.paddingRight) || 0)
      let totalWidth; let curX; let distanceToStart; let distanceToLoop; let item; let i

      populateWidths()
      $gsap.set(items, {
        xPercent: i => xPercents[i],
      })
      $gsap.set(items, { x: 0 })
      totalWidth = getTotalWidth()
      for (i = 0; i < length; i++) {
        item = items[i]
        curX = xPercents[i] / 100 * widths[i]
        distanceToStart = item.offsetLeft + curX - startX
        distanceToLoop = distanceToStart + widths[i] * $gsap.getProperty(item, 'scaleX')

        // Adjusting the distance to start by adding the margin
        distanceToStart += i * margin
        tl.to(item, { xPercent: snap((curX - distanceToLoop) / widths[i] * 100), duration: distanceToLoop / pixelsPerSecond }, 0)
          .fromTo(item, { xPercent: snap((curX - distanceToLoop + totalWidth) / widths[i] * 100) }, { xPercent: xPercents[i], duration: (curX - distanceToLoop + totalWidth - curX) / pixelsPerSecond, immediateRender: false }, distanceToLoop / pixelsPerSecond)
          .add(`label${i}`, distanceToStart / pixelsPerSecond)
        times[i] = distanceToStart / pixelsPerSecond
      }
      function toIndex(index, vars) {
        vars = vars || {};
        (Math.abs(index - curIndex) > length / 2) && (index += index > curIndex ? -length : length)

        const newIndex = $gsap.utils.wrap(0, length, index)
        let time = times[newIndex]
        if (time > tl.time() !== index > curIndex) {
          vars.modifiers = { time: $gsap.utils.wrap(0, tl.duration()) }
          time += tl.duration() * (index > curIndex ? 1 : -1)
        }
        curIndex = newIndex
        vars.overwrite = true

        return tl.tweenTo(time, vars)
      }
      tl.next = vars => toIndex(curIndex + 1, vars)
      tl.previous = vars => toIndex(curIndex - 1, vars)
      tl.current = () => curIndex
      tl.toIndex = (index, vars) => toIndex(index, vars)
      tl.updateIndex = () => curIndex = Math.round(tl.progress() * (items.length - 1))
      tl.times = times
      tl.progress(1, true).progress(0, true)
      if (config.reversed) {
        tl.vars.onReverseComplete()
        tl.reverse()
      }
      if (config.draggable && typeof ($Draggable) === 'function') {
        const proxy = document.createElement('div')
        const wrap = $gsap.utils.wrap(0, 1)
        let ratio; let startProgress; let draggable; let dragSnap; let roundFactor
        const align = () => tl.progress(wrap(startProgress + (draggable.startX - draggable.x) * ratio))
        const syncIndex = () => tl.updateIndex()

        typeof (InertiaPlugin) === 'undefined' && console.warn('InertiaPlugin required for momentum-based scrolling and snapping. https://greensock.com/club')
        draggable = $Draggable.create(proxy, {
          trigger: items[0].parentNode,
          type: 'x',
          onPress() {
            startProgress = tl.progress()
            tl.progress(0)
            populateWidths()
            totalWidth = getTotalWidth()
            ratio = 1 / totalWidth
            dragSnap = totalWidth / items.length
            roundFactor = 10 ** ((`${dragSnap}`).split('.')[1] || '').length
            tl.progress(startProgress)
          },
          onDrag: align,
          onThrowUpdate: align,
          inertia: true,
          snap: value => {
            const n = Math.round(Number.parseFloat(value) / dragSnap) * dragSnap * roundFactor

            return (n - n % 1) / roundFactor
          },
          onRelease: syncIndex,
          onThrowComplete: () => $gsap.set(proxy, { x: 0 }) && syncIndex(),
        })[0]
      }

      return tl
    }
  }
})
</script>

<template>
  <div class="mx-8 mx-md-16">
    <VRow class="text-ltrenovate text-subtitle-1">
      <VCol>
        <span>[ 04 ]</span>
      </VCol>
      <VCol>
        <span class="d-flex justify-end text-uppercase text-no-wrap">
          [ {{ $t('community.our_community') }} ]
        </span>
      </VCol>
    </VRow>

    <VRow>
      <VCol
        cols="12"
        md="8"
        class="my-6 my-md-10"
      >
        <h1 class="text-h1 mb-0">
          {{ $t("community.be_part_of_the_community") }}
        </h1>
      </VCol>

      <VCol
        cols="12"
        md="4"
        class="d-flex align-end pt-0"
      >
        <span class="text-h6 text-geomanist">
          {{ $t("community.ready_to_embark_desc") }}
        </span>
      </VCol>
    </VRow>

    <VRow>
      <VCol offset-md="8">
        <VImg
          :src="downArrow"
          width="37"
          class=" mb-10 mb-sm-16 mb-md-10"
        />
      </VCol>
    </VRow>

    <div class="card-container mt-md-10 overflow-hidden">
      <VCard
        v-for="(community, index) in communities"
        :key="index"
        class="card rounded-0 pa-1"
      >
        <div class="d-flex flex-column inner-border pa-8">
          <VImg
            :src="community.logo"
            width="36"
            height="30"
          />
          <span class="mt-16 my-5 text-body-1 text-ltrenovate">
            {{ $t(community.content) }}
          </span>

          <VBtn
            color="#1C1C1C"
            class="community-button"
            block
            @click="navigateTo(community.link)"
          >
            <div class="d-flex w-100 justify-space-between align-center">
              <div>
                <span class="text-uppercase text-body-2 text-ltrenovate">{{ $t(community.name) }}</span>
              </div>
              <div>
                <VImg
                  :src="webIcon"
                  width="10"
                  height="10"
                />
              </div>
            </div>
          </VBtn>
        </div>
      </VCard>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.inner-border{
  border: 1px solid red;
}

.community-button {
  display: block !important;
}

.card {
  flex: 0 0 auto;
  border: 1px solid red;
  width: 324px;
  margin-right: 30px;
  margin-bottom: 30px;
}

.card-container {
  display: grid;
}

@media(max-width:600px){
  .card-container {
    grid-template-columns: 1fr;
  }
}

@media(min-width:600px){
  .card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media(min-width:960px){
  .card-container {
    grid-template-columns: repeat(5, 1fr);
  }
}
</style>
