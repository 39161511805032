<script setup>
const { $gsap, $event } = useNuxtApp();
const rocketImages = import.meta.glob("/assets/horizontal-rocket-clips/*.webp");
const rocketCanvas = ref(null);
const rocketImageCount = ref(Object.keys(rocketImages).length);
const rocketImageArray = ref([]);
const rocketTokens = {
  frame: 0,
};
let rocketContext;

const loadRocketImages = async () => {
  // Create an array of promises for loading images concurrently
  const loadPromises = Object.values(rocketImages).map(
    async (loadImageFunc) => {
      const image = await loadImageFunc();
      const img = new Image();
      img.src = image.default;
      return img;
    }
  );

  // Wait for all the image loading promises to resolve
  rocketImageArray.value = await Promise.all(loadPromises);

  if (rocketImageArray.value.length == rocketImageCount.value) {
    rocketImageArray.value[rocketImageCount.value-1].onload = () => {
      renderRocket();
    };
  }
};

const renderRocket = () => {
  if (rocketContext && rocketCanvas.value) {
    rocketContext.clearRect(
      0,
      0,
      rocketCanvas.value.width,
      rocketCanvas.value.height
    );
    rocketContext.drawImage(rocketImageArray.value[rocketTokens.frame], 0, 0);
    $event("horizontalRocketImagesLoaded");
  }
};

onMounted(() => {
  rocketContext = rocketCanvas.value.getContext("2d");
  if (rocketContext) {
    rocketCanvas.value.width = 1920;
    rocketCanvas.value.height = 1080;
    loadRocketImages();

    $gsap.to(rocketTokens, {
      frame: rocketImageCount.value - 1,
      snap: "frame",
      ease: "none",
      scrollTrigger: {
        trigger: ".rocket-animation-container",
        start: "top top",
        end: "+=3500",
        pin: true,
        scrub: 1,
      },
      onUpdate: renderRocket,
    });
  }
});
</script>

<template>
  <div class="rocket-animation-container d-flex justify-center">
    <canvas ref="rocketCanvas" />
  </div>
</template>

<style lang="scss" scoped>
@use "@configured-variables" as variables;

.rocket-animation-container {
  height: 100vh;
  overflow: hidden;
}

canvas {
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: calc(100vh - variables.$layout-horizontal-nav-navbar-height);
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
