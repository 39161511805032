<script setup>
const { $gsap, $event } = useNuxtApp();
const allImages = import.meta.glob("/assets/view-all-tokens/*.webp");
const animation = ref(null);
const frameCount = ref(Object.keys(allImages).length);
const images = ref([]);
const tokens = {
  frame: 0,
};
let context;

const loadImages = async () => {
  // Create an array of promises for loading images concurrently
  const loadPromises = Object.values(allImages).map(async (loadImageFunc) => {
    const image = await loadImageFunc();
    const img = new Image();
    img.src = image.default;
    return img;
  });

  // Wait for all the image loading promises to resolve
  images.value = await Promise.all(loadPromises);

  if (images.value.length == frameCount.value) {
    images.value[frameCount.value-1].onload = () => {
      render();
    };
  }
};

const render = () => {
  if (context && animation.value) {
    context.clearRect(0, 0, animation.value.width, animation.value.height);
    context.drawImage(images.value[tokens.frame], 0, 0);
    $event("transitionImagesLoaded");
  }
};

onMounted(() => {
  context = animation.value.getContext("2d");

  if (context) {
    animation.value.width = 1920;
    animation.value.height = 1080;
    loadImages();

    $gsap.to(tokens, {
      frame: frameCount.value - 1,
      snap: "frame",
      ease: "none",
      scrollTrigger: {
        trigger: ".animation-container",
        start: "top top",
        end: "+=3500",
        pin: true,
        scrub: 1,
      },
      onUpdate: render,
    });
  }
});
</script>

<template>
  <div class="animation-container d-flex justify-center">
    <canvas ref="animation" />
  </div>
</template>

<style lang="scss" scoped>
@use "@configured-variables" as variables;

.animation-container {
  height: 100vh;
  overflow: hidden;
}

canvas {
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: calc(100vh - variables.$layout-horizontal-nav-navbar-height);
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  scale: 1.1;
  transform-origin: top left;
}
</style>
