<script setup>
import Player from '@vimeo/player'
import { useDisplay } from "vuetify";
const { smAndUp } = useDisplay();
const { $gsap } = useNuxtApp();
const { getIdFromVimeoURL } = useUtilities()
const config = useRuntimeConfig()
const url = config.public.landingSection3VideoBackground

const videoId = getIdFromVimeoURL(url)
const vimeoPlayer = ref(null)
let player = null
const playButton = ref(false)
const play = ref(false)
const vimeoURL = `https://player.vimeo.com/video/${videoId}`
const vimeoError = ref(false)

const onPlay = () => {
  play.value = !play.value
  play.value ? player.play() : player.pause();
};

const onMouseMove = (event) => {
  $gsap.to(".play-btn", {
    x: event.layerX - 55,
    y: event.layerY - 40,
    duration: 0.3,
  });
};

onMounted(() => {
  player = new Player(vimeoPlayer.value, {
    url: vimeoURL,
    loop: true,
    autoplay: false,
    transparent: false,
    byline: false,
    playsinline: false,
    title: false,
    quality: "1080p",
    portrait: false,
    speed: false,
    pip: false,
    transcript: false,
    cc: false
  })
  player.ready().catch((error) => { 
    console.log(error)
    vimeoError.value = true
  })
})

onBeforeUnmount(() => {
  if (player) {
    player.destroy()
  }
})

</script>

<template>
  <div class="section3-container py-16 pb-sm-16 px-2 px-md-12">
    <div
      class="video-wrapper"
    >
      <div
        ref="vimeoPlayer"
        class="video"
        :style="smAndUp ? 'pointer-events: none;' : ''"
      />
      <div
        v-if="smAndUp"
        class="button-wrapper" 
        @mouseenter="playButton = true"
        @mouseleave="playButton = false"
        @mousemove="onMouseMove"
      >
        <VBtn
          v-show="playButton && !vimeoError"
          class="play-btn"
          rounded="circle"
          width="114px"
          min-height="114px"
          @click="onPlay"
        >
          <span class="font-ltrenovate">
            {{ play ? $t("video.pause_video") : $t("video.play_video") }}
          </span>
        </VBtn>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@configured-variables" as variables;

.section3-container {
  height: fit-content;

  @media (min-width: 960px) {
    margin-top: 186px;
    margin-bottom: 186px;
  }
}

.video-wrapper {
  --video--width: 960;
  --video--height: 540;

  position: relative;
  padding-bottom: calc(var(--video--height) / var(--video--width) * 100%); /* 41.66666667% */
  overflow: hidden;
  max-width: 100%;
  background: black;
}

.video:deep(iframe) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.button-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
}
</style>
