<script setup>
import { VNodeRenderer } from "@layouts/components/VNodeRenderer";
import { themeConfig } from "@themeConfig";
import imgDiscoverArrow from "@images/front-pages/landing/discover-arrow.png";
import imgBrandDApps from "@images/front-pages/landing/brand_dapps.png";
import { useDisplay } from "vuetify";

const config = useRuntimeConfig();
const zwapLink = config.public.zwapLink;

const navigateTo = (url) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

const { mdAndUp, sm } = useDisplay();
</script>

<template>
  <div
    class="mx-4 mx-sm-8 mx-md-16 bg-grey rounded-xl px-5 px-md-16 py-16 py-sm-8 text-black"
  >
    <VRow class="align-center">
      <VCol>
        <span class="text-ltrenovate text-black text-subtitle-1">[ 02 ]</span>
      </VCol>
      <VCol>
        <span
          class="d-flex justify-end text-uppercase text-no-wrap text-ltrenovate text-black text-subtitle-1"
        >
          [ {{ $t("ecosystem.official_dapps") }} ]
        </span>
      </VCol>
    </VRow>

    <VRow class="my-6 my-md-10">
      <VCol md="8" cols="12">
        <div>
          <h1 class="text-wrap text-h1 text-black">
            {{ $t("ecosystem.join_the_zchains_ecosystem[0]") }}
            <i>
              <span class="text-primary">{{
                $t("ecosystem.join_the_zchains_ecosystem[1]")
              }}</span>
              {{ $t("ecosystem.join_the_zchains_ecosystem[2]") }}
            </i>
          </h1>
        </div>
      </VCol>

      <!-- <VCol md="4" class="d-md-flex align-md-end justify-md-end">
        <div class="d-flex justify-space-between align-md-end mb-md-8 large-screen-underline">
          <div class="text-uppercase text-h6 text-black small-screen-underline text-ltrenovate">
            {{ $t("ecosystem.discover_more") }}
          </div>
          <div class="ml-md-3">
            <VImg :src="imgDiscoverArrow" width="32" />
          </div>
        </div>
      </VCol> -->
    </VRow>

    <div class="pa-0 py-5 ma-0 d-flex flex-column flex-md-row ga-10">
      <div
        class="mx-0 ga-md-10 ga-5 d-flex flex-column flex-sm-row mx-sm-5 mx-md-0"
        :class="[{ 'w-75': mdAndUp }]"
      >
        <div class="d-flex flex-column align-center">
          <div class="d-flex flex-column position-relative card-class ">
            <div class="box position-absolute box-position-tn1" />
            <div class="box position-absolute box-position-tn2" />
            <VCard
              class="position-relative px-6 px-md-10 py-16 py-md-10 d-flex flex-column"
              style="border-radius: 0"
              @click="navigateTo(zwapLink)"
            >
              <div class="d-flex flex-grow-1">
                <img src="/images/dapp/Zwap.png" alt="Zwap" width="120"/>
              </div>

              <div class="my-10 mb-sm-10 py-10 mt-sm-10">
                <span
                  class="text-h6 d-block font-weight-bold text-primary text-ltrenovate mb-2"
                >
                  {{ $t("ecosystem.zwap") }}
                </span>
                <span class="text-subtitle-1 text-geomanist">
                  {{ $t("ecosystem.zwap_desc") }}
                </span>
              </div>
              <div class="d-flex align-center justify-space-between ">
                <div class="d-flex">
                  <div class="grey-badge rounded-lg">
                    <span class="text-uppercase">{{
                      $t("ecosystem.defi")
                    }}</span>
                  </div>
                  <div class="ml-2 grey-badge rounded-lg">
                    <span class="text-uppercase">{{
                      $t("ecosystem.dex")
                    }}</span>
                  </div>
                </div>

                <div>
                  <VImg width="32" :src="imgDiscoverArrow" />
                </div>
              </div>
            </VCard>
          </div>
        </div>

        <div class="d-flex flex-column align-center">
          <div class="d-flex flex-column position-relative card-class">
            <div class="box position-absolute box-position-bn1" />
            <div class="box position-absolute box-position-bn2" />
            <VCard
              class="position-relative px-6 px-md-10 py-16 py-md-10 d-flex flex-column"
              height="100%"
              style="border-radius: 0"
            >
              <div class="coming-soon-overlay">
                <span class="text-subtitle-1 text-geomanist">
                  [{{ $t("ecosystem.coming_soon") }}]
                </span>
              </div>
              <div class="d-flex justify-space-between">
                <div>
                  <VImg :src="imgBrandDApps" width="110" />
                </div>
                <div class="d-flex align-start">
                  <span class="red-badge rounded-lg">{{
                    $t("ecosystem.coming_soon")
                  }}</span>
                </div>
              </div>
              <div class="my-10">
                <span
                  class="text-h6 d-block font-weight-bold text-primary text-ltrenovate"
                >
                  {{ $t("ecosystem.tokenization") }}
                </span>
                <span class="text-subtitle-1 text-geomanist">
                  {{ $t("ecosystem.tokenization_desc") }}
                </span>
              </div>
              <div class="d-flex justify-space-between">
                <div class="grey-badge rounded-lg">
                  <span class="text-uppercase">
                    {{ $t("ecosystem.rwa") }}
                  </span>
                </div>
                <div>
                  <VImg width="32" :src="imgDiscoverArrow" />
                </div>
              </div>
            </VCard>
          </div>
        </div>
      </div>
      <div class="d-flex align-md-end ps-md-8" :class="[{ 'w-25': mdAndUp }, { 'w-50': sm }]">
        <span class="text-geomanist text-black text-h6 ml-md-auto pa-1 pa-md-0">{{
          $t("ecosystem.discover_top_dapps_desc")
        }}</span>
      </div>
    </div>

    <VRow class="mt-3">
      <VCol class="text-3xl py-0"> + </VCol>
      <VCol class="d-flex justify-end text-3xl py-0"> + </VCol>
    </VRow>
  </div>
</template>

<style lang="scss" scoped>
.bg-grey {
  background-color: #d9d9d9;
}

.red-badge {
  background-color: #e10600b2;
  padding: 5px;
  font-size: 12px;
  border: 1px solid #ff0000;
}

.grey-badge {
  background-color: #3c3c3c;
  color: white;
  padding: 8px 20px;
  font-size: 12px;
}

.coming-soon-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.coming-soon-overlay > span {
  border-radius: 0.5rem;
  background-color: #e10600;
  color: white;
  padding: 20px 30px 22px 30px;
}

.box {
  border: 1px solid red;
  height: 100%;
  width: 100%;
}

.box-position-tn1 {
  top: -15px;
  left: -15px;
}

.box-position-tn2 {
  top: -10px;
  left: -10px;
}

.box-position-bn1 {
  bottom: -15px;
  right: -15px;
}

.box-position-bn2 {
  bottom: -10px;
  right: -10px;
}

@media (max-width: 599px) {
  .card-class {
    width: 100%;
    max-width: 387px;
    min-height: 387px;
  }
}

@media (min-width: 600px) {
  .small-screen-underline {
    position: relative;
    display: inline-block;
  }

  .small-screen-underline::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    border-bottom: 2px solid red;
  }

  .small-screen-underline::before {
    content: "";
    position: relative;
    bottom: 3px;
    left: 0;
    width: 100%;
    border-bottom: 2px solid red;
  }

  .card-class{
    min-width: 100%;
    max-width: 380px;
    height: 100%;
    max-height: fit-content;
  }
}

@media (min-width: 960px) {
  .large-screen-underline {
    position: relative;
    display: inline-block;
  }

  .large-screen-underline::after {
    content: "";
    position: absolute;
    bottom: -12px;
    left: 0;
    width: 100%;
    border-bottom: 2px solid red;
  }

  .large-screen-underline::before {
    content: "";
    position: absolute;
    bottom: -16px;
    left: 0;
    width: 100%;
    border-bottom: 2px solid red;
  }

  .card-class {
    min-width: 100%;
    max-width: 390px;
    min-height: 100%;
    max-height: fit-content;
  }
}
</style>
