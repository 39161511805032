<script setup>
import "vidstack/bundle";
import tokenVideo from "@/assets/videos/F_W_ZToken.webm";
import DownArrowSVG from "@images/svg/down-arrow.svg";
import TotalSuppplySVG from "@images/svg/total-supply.svg";
import TeamHoldingSVG from "@images/svg/team-holding.svg";
import FoundationSVG from "@images/svg/foundation.svg";
import MarketingSVG from "@images/svg/marketing.svg";
import AllocationSVG from "@images/svg/allocation.svg";
import mexcLogo from "assets/images/front-pages/landing/mexc-logo.png";
import lbankLogo from "assets/images/front-pages/landing/lbank-logo.png";
import bitmartLogo from "assets/images/front-pages/landing/bitmart-logo.png";
import twobLogo from "assets/images/front-pages/landing/2b-logo.png";
import gateioLogo from "assets/images/front-pages/landing/gateio-logo.png";
import xtLogo from "assets/images/front-pages/landing/xt-logo.png";
import bingxLogo from "assets/images/front-pages/landing/bingx-logo.png";
import coinwLogo from "assets/images/front-pages/landing/coinw-logo.png";

const config = useRuntimeConfig();
const mexcLink = config.public.mexcLink;
const lbankLink = config.public.lbankLink;
const bitmartLink = config.public.bitmartLink;
const twobLink = config.public.twobLink;
const gateioLink = config.public.gateioLink;
const xtLink = config.public.xtLink;
const bingxLink = config.public.bingxLink;
const coinwLink = config.public.coinwLink;

const logos = ref([
  { image: mexcLogo, link: mexcLink },
  { image: lbankLogo, link: lbankLink },
  { image: bitmartLogo, link: bitmartLink },
  { image: twobLogo, link: twobLink },
  // { image: gateioLogo, link: gateioLink  },
  // { image: xtLogo, link: xtLink  },
  // { image: bingxLogo, link: bingxLink  },
  // { image: coinwLogo, link: coinwLink  },
]);

const { $gsap, $ScrollTrigger } = useNuxtApp();

let animation;
const thePowerOfZCD = ref(null);

const fadeIn = () => {
  animation.play();
};

const fadeInOut = () => {
  animation = $gsap
    .timeline({ paused: true })
    .from(".team-holding", { opacity: 0, duration: 0.6 })
    .from(".foundation", { opacity: 0, duration: 0.6 }, "-=0.3")
    .from(".marketing", { opacity: 0, duration: 0.6 }, "-=0.3")
    .from(".allocation", { opacity: 0, duration: 0.6 }, "-=0.3")
    .from(".total-supply", { opacity: 0, duration: 0.6 }, "-=0.3");
};

const triggerAnimation = () => {
  $ScrollTrigger.create({
    trigger: ".token-wrapper",
    start: "35% 80%",
    end: "80% 20%",
    onEnter: (self) => self.isActive && fadeIn(),
  });
};

const scrollToThePowerOfZCD = () => {
  thePowerOfZCD.value.scrollIntoView({ behavior: "smooth" });
};

defineExpose({
  scrollToThePowerOfZCD,
});
</script>

<template>
  <div class="py-16 mx-8 section6-container">
    <div class="section6-title mt-16">
      <div class="px-md-10">
        <div
          class="mb-8 d-flex justify-space-between text-ltrenovate text-subtitle-1"
        >
          <span> [ 03 ] </span>
          <span class="text-uppercase">
            [ {{ $t("creating.creating_a_sustainable_future") }} ]
          </span>
        </div>
        <div class="my-6 my-md-10 d-flex">
          <span class="section6-content text-h3 text-geomanist mr-16">
            {{ $t("creating.content") }}
          </span>
          <span class="d-flex align-end">
            <DownArrowSVG />
          </span>
        </div>
      </div>
    </div>

    <div class="token-wrapper mt-16 mt-md-0">
      <media-player
        :src="tokenVideo"
        autoPlay
        muted
        loop
        class="token-container"
        :playsinline="true"
      >
        <media-provider class="token" />
      </media-player>
      <div class="content mt-12 mt-md-0">
        <div class="h-100 d-md-flex justify-space-between align-center">
          <div class="mb-10 mb-md-0 total-supply d-flex flex-column ga-1">
            <span class="text-h6 text-ltrenovate">
              {{ $t("creating.total_supply") }}
            </span>
            <span class="text-h1 text-geomanist text-primary mb-6"> 15B </span>
            <TotalSuppplySVG />
          </div>
          <div class="d-flex flex-column gap-3">
            <div class="team-holding d-flex flex-column ga-2">
              <span class="text-subtitle-1 text-ltrenovate">
                {{ $t("creating.team_holding") }}
              </span>
              <div class="d-flex align-center">
                <TeamHoldingSVG class="mt-2" />
                <span class="ml-3 text-primary text-h5 text-geomanist">
                  2B
                </span>
              </div>
              <VDivider />
            </div>
            <div class="foundation d-flex flex-column ga-2">
              <span class="text-subtitle-1 text-ltrenovate">
                {{ $t("creating.foundation") }}
              </span>
              <div class="d-flex align-center">
                <FoundationSVG class="mt-2" />
                <span class="ml-3 text-primary text-h5 text-geomanist">
                  1B
                </span>
              </div>
              <VDivider />
            </div>
            <div class="marketing d-flex flex-column ga-2">
              <span class="text-subtitle-1 text-ltrenovate">
                {{ $t("creating.marketing") }}
              </span>
              <div class="d-flex align-center">
                <MarketingSVG class="mt-2" />
                <span class="ml-3 text-primary text-h5 text-geomanist">
                  2B
                </span>
              </div>
              <VDivider />
            </div>
            <div class="allocation d-flex flex-column ga-2">
              <span class="text-subtitle-1 text-ltrenovate">
                {{ $t("creating.cex_allocation") }}
              </span>
              <div class="d-flex align-center">
                <AllocationSVG class="mt-2" />
                <span class="ml-3 text-primary text-h5 text-geomanist">
                  10B
                </span>
              </div>
              <VDivider />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-16" ref="thePowerOfZCD">
      <div class="d-flex justify-center my-3">
        <h1 class="text-h1 text-center pt-16">
          <span class="text-primary font-italic">
            {{ $t("creating.the_power_of_ZCD[0]") }}
          </span>
          <span>
            {{ $t("creating.the_power_of_ZCD[1]") }}
          </span>
        </h1>
      </div>

      <VContainer>
        <VRow class="mt-16">
          <VCol v-for="(logo, index) in logos" :key="index" cols="6" sm="3">
            <NuxtLink :to="logo.link" target="_blank">
              <VImg :src="logo.image" width="180" height="80" class="w-100" />
            </NuxtLink>
          </VCol>
        </VRow>
      </VContainer>

      <!-- <div class="d-flex justify-center">
        <VBtn>
          <span class="text-geomanist">{{ $t('creating.buy_now') }}</span>
        </VBtn>
      </div> -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@configured-variables" as variables;

.section6-container {
  height: fit-content;
}

.section6-content {
  z-index: 100;
}

.token-wrapper {
  @media (min-width: 970px) {
    position: relative;
    height: 100vh;
  }
}

.content {
  position: relative;
  z-index: 100;

  @media (min-width: 970px) {
    height: 100vh;
    margin-left: 5%;
    margin-right: 1%;
  }
  @media (min-width: 1050px) {
    margin-left: 10%;
    margin-right: 6%;
  }
  @media (min-width: 1200px) {
    margin-left: 12%;
    margin-right: 10%;
  }
}

.token-container {
  overflow: hidden;
  position: relative;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  width: 100%;

  @media (min-width: 960px) {
    position: absolute;
    transform: translate(-50%, -50%);
    transform-origin: top left;
    min-height: 100vh;
    width: 100vw;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    top: 50%;
    left: 50%;
  }
}

.token {
  overflow: hidden;
  transform-origin: center;
  width: 100%;
  height: 100%;

  @media (max-width: 600px) {
    transform: scale(1.8);
  }
  @media (min-width: 600px) and (max-width: 960px) {
    transform: scale(1.5);
  }
  @media (min-width: 960px) {
    transform: scale(0.9);
  }
}
</style>
