<script setup>
import downArrow from '@images/front-pages/landing/down-arrow.png'
import numberOne from '@images/front-pages/landing/number-one.png'
import numberTwo from '@images/front-pages/landing/number-two.png'
import numberThree from '@images/front-pages/landing/number-three.png'

const { $gsap, $event } = useNuxtApp()
const imageImports = import.meta.glob('assets/vertical-rocket-clips/*.webp')
const images = ref([])

const loadImages = async () => {
  const loadedImages = await Promise.all(
    Object.keys(imageImports).map(async path => {
      const image = await imageImports[path]()

      return image.default
    }),
  )
  images.value = loadedImages
  $event('verticalRocketImagesLoaded')
}

const currentImageIndex = ref(0)

onMounted(async () => {
  await loadImages()

  // image sequence
  const sequenceElement = document.getElementById('sequence')
  if (sequenceElement) {
    $gsap.timeline({
      scrollTrigger: {
        trigger: '#triggerImage',
        start: 'top 50%',
        end: `+=${sequenceElement.offsetHeight}`,
        scrub: true
      }
    })
    .to(currentImageIndex, {
      value: images.value.length - 1,
      roundProps: 'value',
      onUpdate: () => {
        const imagesArray = sequenceElement.getElementsByTagName('img')
        for (let i = 0; i < imagesArray.length; i++) {
          imagesArray[i].style.display = currentImageIndex.value === i ? 'block' : 'none'
        }
      }
    })
  }

  // box animations
  if (window.innerWidth > 600) {
  const boxElements = [
    { id: '#box1', trigger: '#triggerBox1', start: 'top 80%' },
    { id: '#box2', trigger: '#triggerBox2', start: 'top 60%' },
    { id: '#box3', trigger: '#triggerBox3', start: 'top 40%' }
  ]
  boxElements.forEach(box => {
    $gsap.fromTo(box.id, { opacity: 0 }, {
      opacity: 1,
      scrollTrigger: {
        trigger: box.trigger,
        start: box.start,
        toggleActions: 'play none none none'
      }
    })
  })
}
})
</script>

<template>
  <div class="mt-16 mx-8 mx-md-16">
    <VRow class="text-ltrenovate text-subtitle-1">
      <VCol>
        <span>[ 01 ]</span>
      </VCol>
      <VCol>
        <span class="d-flex justify-end text-uppercase text-no-wrap">
          [ {{ $t('limitless.empowering_businesses') }} ]
        </span>
      </VCol>
    </VRow>

    <VRow>
      <VCol cols="12" md="8" class="my-6 my-md-10">
        <h1 class="mb-3 text-h1 d-flex flex-column">
          <i><span>{{ $t("limitless.limitless_possibilities[0]") }}</span></i>
          <span>{{ $t("limitless.limitless_possibilities[1]") }}</span>
        </h1>
      </VCol>

      <VCol cols="12" md="4" class="d-flex align-end pt-0">
        <span class="text-h6 text-geomanist mb-0">
          {{ $t("limitless.build_any_dapps_desc") }}
        </span>
      </VCol>
    </VRow>

    <VRow>
      <VCol offset-md="8">
        <VImg :src="downArrow" width="37" />
      </VCol>
    </VRow>

    <!-- rocket image and 3 boxes -->
    <div id="triggerImage" class="position-sm-relative mt-16">
      <!-- rocket -->
      <div class="d-flex position-sm-relative z-1">
        <div id="sequence" style="height: 100vh;" class="position-sm-relative w-100 overflow-hidden">
          <img v-for="(image, index) in images" :key="index" :src="image"
            :style="{ display: currentImageIndex === index ? 'block' : 'none' }">
        </div>
      </div>

      <!-- first box -->
      <div id="triggerBox1" class="d-sm-flex position-sm-absolute z-2 box1">
        <div id="box1" class="box pa-1">
          <div class="border-red px-5 px-sm-10 py-10 box-container d-flex align-center">
            <div class="d-flex">
              <div>
                <VImg :src="numberOne" class="number-size" />
              </div>
              <div class="ml-5 ml-sm-10">
                <p class="text-h4 text-ltrenovate text-primary">
                  {{ $t('limitless.rwa_tokenization_ready') }}
                </p>
                <span class="text-geomanist text-h6 mb-0">
                  {{ $t('limitless.tokenize_real_world_desc') }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- second box -->
      <div id="triggerBox2" class="d-sm-flex position-sm-absolute z-2 ml-sm-4 box2 mt-8 mt-sm-0">
        <div id="box2" class="box pa-1">
          <div class="border-red px-5 px-sm-10 py-10 box-container d-flex align-center">
            <div class="d-flex">
              <div>
                <VImg :src="numberTwo" class="number-size" />
              </div>
              <div class="ml-5 ml-sm-10">
                <p class="text-h4 text-ltrenovate text-primary">
                  {{ $t('limitless.journey_to_web3') }}
                </p>
                <span class="text-geomanist text-h6 mb-0">
                  {{ $t('limitless.build_web3_games_desc') }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- third box -->
      <div id="triggerBox3" class="d-sm-flex position-sm-absolute z-2 mr-sm-16 box3 mt-8 mt-sm-0">
        <div id="box3" class="box pa-1">
          <div class="border-red px-5 px-sm-10 py-10 box-container d-flex align-center">
            <div class="d-flex">
              <div>
                <VImg :src="numberThree" class="number-size" />
              </div>
              <div class="ml-5 ml-sm-10">
                <p class="text-h4 text-ltrenovate text-primary">
                  {{ $t('limitless.transparent_defi') }}
                </p>
                <span class="text-geomanist text-h6 mb-0">
                  {{ $t('limitless.build_any_decentralized_desc') }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.border-red {
  border: 1px solid rgb(var(--v-global-theme-primary));
}

.box {
  border: 1px solid rgb(var(--v-global-theme-primary));
  color: white;
  background: rgba(0, 0, 0, 0.7);
}

#sequence {
  display: flex;
  justify-content: center;
  align-items: center;
}

#sequence img {
  height: 100%;
}

@media (max-width: 600px) {
  .number-size {
    width: 40px;
    height: 65px;
  }
}

@media (min-width: 600px) {
  .z-1 {
    z-index: 1;
  }
  .z-2 {
    z-index: 2;
  }
  .number-size {
    width: 40px;
    height: 65px;
  }

  .box-container {
    width: 450px;
    height: 297px;
  }
  .position-sm-relative {
    position: relative;
  }
  .position-sm-absolute {
    position: absolute;
  }
  .box1 {
    right: 0;
    top: 0;
  }
  .box2 {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .box3 {
    right: 0;
    bottom: 0;
  }
}

@media (min-width: 1000px) {
  .number-size {
    width: 45px;
    height: 77px;
  }
}
</style>
