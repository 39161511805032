<script setup>
import Section1 from "@/views/landing/section1.vue"
import Section2 from "@/views/landing/section2.vue"
import Section3 from "@/views/landing/section3.vue"
import Section4 from "@/views/landing/section4.vue"
import Section5 from "@/views/landing/section5.vue"
import Section6 from "@/views/landing/section6.vue"
import Section7 from "@/views/landing/section7.vue"
import Section8 from "@/views/landing/section8.vue"
import AnimatedTransitionSection from "@/views/landing/AnimatedTransitionSection.vue"
import AnimatedRocketSection from "@/views/landing/AnimatedRocketSection.vue"

const { loadingVerticalRocket, loadingHorizontalRocket, loadingTransition } = useLoadingState()
const { scrolled } = useScrollStateState()
const { $listen } = useNuxtApp()

$listen("verticalRocketImagesLoaded", () => {
  loadingVerticalRocket.value = false
});

$listen("horizontalRocketImagesLoaded", () => {
  loadingHorizontalRocket.value = false
});

$listen("transitionImagesLoaded", () => {
  loadingTransition.value = false
});

const route = useRoute();
const thePowerOfZCD = ref(null)

const scrollToThePowerOfZCDSection = () => {
  thePowerOfZCD.value.scrollToThePowerOfZCD()
  scrolled.value = true
}

const loaded = computed(() => {
  return !loadingVerticalRocket.value && !loadingHorizontalRocket.value && !loadingTransition.value
})

onBeforeUnmount(() => {
  loadingVerticalRocket.value = true
  loadingHorizontalRocket.value = true
  loadingTransition.value = true
})


watch(
  [() => route.hash, () => loaded.value],
  ([routeHash, loaded]) => {
    if (routeHash === '#the-power-of-ZCD' && loaded) {
      setTimeout(() => {
        scrollToThePowerOfZCDSection()
      }, 500)
    }
  }
)

watch(
  [() => route.hash, () => loaded.value, () => scrolled.value],
  ([routeHash, loaded, scrolled]) => {
    if (routeHash === '#the-power-of-ZCD' && loaded && !scrolled) {
      setTimeout(() => {
        scrollToThePowerOfZCDSection()
      }, 500)
    }
  }
)

</script>

<template>
  <Head>
    <Title>Home</Title>
    <Meta
      name="description"
      content="ZChains is designed to be a high-performance network that builds on the Proof of Stake (PoS) consensus. With EVM compatibility, ZChains allows seamless integration with existing Ethereum-based dApps."
    />
  </Head>

  <div
    :class="[
      'landing-page-wrapper',
      {
        'is-loading':
          loadingVerticalRocket || loadingHorizontalRocket || loadingTransition,
      },
    ]"
  >
    <div
      v-if="
        loadingVerticalRocket || loadingHorizontalRocket || loadingTransition
      "
      class="page-loader"
    >
      <l-pinwheel :size="60" :stroke="3" :speed="0.8" color="#E10600" />
    </div>
    <!-- Section 1 - Static image hero banner -->
    <Section1 />

    <!-- Section 2 -  ZChains's description -->
    <Section2 />

    <!-- Section 3 - Pure video background - Vimeo -->
    <Section3 />

    <!-- Section 4  - Limitless Possibilities -->
    <Section4 />

    <!-- Horizontal Rocket Animation -->
    <AnimatedRocketSection />

    <!-- Section 5 - Join the ZChains Ecosystem -->
    <Section5 class="mt-16" />

    <!-- Animated Transition Section -->
    <AnimatedTransitionSection />

    <!-- Section 6 - ZChains is empowered by its native token -->
    <Section6 ref="thePowerOfZCD"/>

    <!-- Section 7 - Be part of the community -->
    <Section7 class="mt-16" />

    <!-- Section 8 - ZChains was mentioned on -->
    <Section8 class="my-16 py-md-16" />
  </div>
</template>
